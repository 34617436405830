<template>
  <div class="claim-reward-page">
    <h1>Welcome back! <br></h1>

    <form
      v-if="!isSubmited"
      action="#"
      class="claim-reward-page-form"
      @submit.prevent="onSubmit"
    >
      <h2 class="claim-reward-page-form-title">
        If you are now a new BAP NFT holder, please submit the form.
      </h2>
      <div class="claim-reward-page-input">
        <label>Buyer Address</label>
        <!-- <input v-model="buyerAddress" /> -->
        <span
          v-if="buyerAddress && signature"
          class="claim-reward-connect-wallet"
        >{{ buyerAddress }}</span>
        <a
          v-else
          class="claim-reward-connect-button"
          href="#"
          @click.prevent="onConnect"
        >Connect</a>
        <div
          v-if="errors.buyerAddress"
          class="claim-reward-page-input-error"
        >
          This field is required
        </div>
      </div>
      <div class="claim-reward-page-input">
        <label>Referrer Address</label>
        <input v-model="referrerAddress">
        <div
          v-if="errors.referrerAddress"
          class="claim-reward-page-input-error"
        >
          This field is required
        </div>
      </div>
      <div class="claim-reward-page-input">
        <label>Tx Hash</label>
        <input v-model="txHash">
        <div
          v-if="errors.txHash"
          class="claim-reward-page-input-error"
        >
          This field is required
        </div>
      </div>
      <!-- <div class="claim-reward-page-input">
      
      </div> -->
      <button
        type="submit"
        class="claim-reward-page-button"
      >
        Claim Reward
      </button>
    </form>
    <div
      v-else
      key="thanks"
      class="claim-reward-page-form"
    >
      <h2>Thank you, form was submitted.</h2>
    </div>
    <div class="claim-reward-page-description">
      Check the
      <router-link :to="{ name: 'rewardleaderboard' }">
        Leaderboard
      </router-link>
      to see when your reward was approved. <br>
      Ask any questions in Discord.
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { shorten } from "../helpers/utils";
import { Config } from "../config/";
import { useWeb3Store } from "@/store/web3";
import { connectWallet } from "@/web3";
export default {
  props: {
    signMessage: Function,
  },
  data() {
    return {
      isSubmited: false,
      referrerAddress: "",
      txHash: "",
      signature: "",
      message: "",
      errors: {},
    };
  },
  computed: {
    buyerAddress() {
      return shorten(this.walletAddress, 4);
    },
    walletAddress() {
      return useWeb3Store().walletAddress;
    },
  },
  watch: {
    walletAddress() {
      this.signature = "";
    },
  },
  methods: {
    async onConnect() {
      await connectWallet()
      this.message = Date.now().toString();
      this.signature = await this.signMessage(this.message);
    },
    validate() {
      this.errors = {};
      if (!this.walletAddress || !this.signature) {
        this.errors.buyerAddress = true;
      }
      if (!this.referrerAddress) {
        this.errors.referrerAddress = true;
      }
      if (!this.txHash) {
        this.errors.txHash = true;
      }
      return !Object.keys(this.errors).length;
    },
    async onSubmit() {
      if (!this.validate()) return;
      try {
        const response = await axios.post(`${Config.apiUrl}/mint/verify`, {
          wallet: this.walletAddress,
          referrerAddress: this.referrerAddress,
          txHash: this.txHash,
          signature: this.signature,
          message: this.message,
        });
        console.log("sended", response);
        this.isSubmited = true;
      } catch (err) {
        alert("Error, please try again");
        console.log("error", err);
      }
    },
  },
};
</script>
